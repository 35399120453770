import React, { useEffect, useState } from 'react'
import { FiAlertCircle } from 'react-icons/fi'

import { Platform, ValidateEmail, ValidateEmailByLength } from '../../helpers'
import useHomepage from '../../hooks/useHomepage'
import Invite from '../../models/Invite'
import UtmContent from '../../models/UtmContent'
import { ApiService } from '../../services'
import { UrlService } from '../../services'
import InlineSpinner from '../common/InlineSpinner'
import './hero-form.less'

type State = {
  url: string
  utmContent: string
  email: string
  emailValid: boolean
  loading: boolean
}

const HeroForm: React.FC = () => {
  const { activeCityName } = useHomepage()
  const [state, setState] = useState<State>({
    url: '',
    utmContent: '',
    email: '',
    emailValid: true,
    loading: false,
  })

  const utmContent = new UtmContent({ position: 'fitness' }).toString()

  useEffect(() => {
    setState({ ...state, utmContent, url: UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }) })
  }, [])

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return
    state.email = state.email.trim()
    const emailValid = ValidateEmail(state.email) && ValidateEmailByLength(state.email)

    if (emailValid) {
      setState({ ...state, emailValid, loading: true })

      ApiService.createInvite(
        {
          email: state.email,
          country_code: 'usa',
          utm: state.utmContent,
          zip: '10001',
          email_marketing_consent: false,
        },
        {
          platform: Platform(),
          location: 'homepage',
        },
      ).then(async ({ data }) => {
        const invite = new Invite(data.invite)
        window.location.href = invite.getRedirectUrl(utmContent)
      })
    } else {
      setState({ ...state, emailValid })
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.value
    setState({ ...state, [target.name]: value })
  }

  return (
    <form className="form form--hero" onSubmit={onSubmitHandler}>
      <input
        className={`form__input form__input--email ${!state.emailValid && 'form__input--error'}`}
        type="text"
        name="email"
        value={state.email}
        placeholder="Your email address"
        onChange={onChangeHandler}
      />
      {!state.emailValid && (
        <div className="form__error-message">
          <FiAlertCircle /> {'Invalid email address'}
        </div>
      )}
      <button className="red-cta" type="submit">
        {state.loading ? <InlineSpinner /> : 'JOIN MEALPAL TODAY'}
      </button>
    </form>
  )
}

export default HeroForm
